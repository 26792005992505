import React from "react";
import { useEnvironment } from "./context/EnvironmentContext";
import JuhuuProvider from "./context/JuhuuContext";
import { BrowserRouter } from "react-router-dom";
import UserProvider from "./context/UserContext";
import PropertyProvider from "./context/PropertyContext";
import Credentials from "./Credentials";
import App from "./App";

function Providers() {
  const { environment } = useEnvironment();

  return (
    <>
      <JuhuuProvider
        config={{
          clientVersion: "1.0.300",
          environment: environment,
          getAccessToken: async () => {
            return localStorage.getItem("accessToken");
          },
          getRefreshToken: async () => {
            return localStorage.getItem("refreshToken");
          },
          onException: async (error) => {
            console.error(error);
            return "abort";
          },
          setAccessToken: async (accessToken) => {
            localStorage.setItem("accessToken", accessToken);
          },
          setRefreshToken: async (refreshToken) => {
            localStorage.setItem("refreshToken", refreshToken);
          },
        }}
      >
        <BrowserRouter basename="/">
          <UserProvider>
            <PropertyProvider>
              <Credentials />
              <App />
            </PropertyProvider>
          </UserProvider>
        </BrowserRouter>
      </JuhuuProvider>
    </>
  );
}

export default Providers;
