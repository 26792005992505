import { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Divider } from "../../components/divider";
import Payment from "../payments/Payment";
import BadgeSessionStatus from "./BadgeSessionStatus";
import SessionType from "./SessionType";
import Timestamp from "../../formatters/Timestamp";
import User from "../users/User";
import { useJUHUU } from "../../context/JuhuuContext";

interface SessionRouteProps {}

const SessionRoute: React.FC<SessionRouteProps> = () => {
  const [session, setSession] = useState<JUHUU.Session.Object | null>(null);
  const { sessionId } = useParams<{ sessionId: string }>();
  const juhuu = useJUHUU();
  const handleRefresh = useCallback(async () => {
    if (sessionId === undefined) {
      return;
    }

    const query = await juhuu.sessions.retrieve({
      sessionId: sessionId,
    });

    if (query.ok === false) {
      return;
    }

    setSession(query.data.session);
  }, [sessionId, juhuu]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
        <div className="flex gap-4 items-center">
          <Heading>
            <SessionType type={session?.type} />
          </Heading>

          <BadgeSessionStatus status={session?.status} />
        </div>
      </div>
      <Divider />
      <div className="flex justify-start flex-row items-center py-4">
        <div className="flex justfy-start flex-col items-start pr-8">
          <Text>Created</Text>
          <Text>
            <Timestamp timestamp={session?.createdAt} />
          </Text>
        </div>

        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>User</Text>
          <User userId={session?.userId} />
        </div>
      </div>
      <Payment paymentId={session?.paymentId} />
    </>
  );
};

export default SessionRoute;
