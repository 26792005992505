import { useCallback, useEffect, useState } from "react";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Divider } from "../../components/divider";
import SessionArray from "../sessions/SessionArray";
import { useProperty } from "../../context/PropertyContext";
import { useJUHUU } from "../../context/JuhuuContext";

interface UserRouteProps {}

const UserRoute: React.FC<UserRouteProps> = () => {
  const [user, setUser] = useState<JUHUU.User.Object | null>(null);
  const { userId } = useParams<{ userId: string }>();
  const { property } = useProperty();
  const juhuu = useJUHUU();
  const handleRefresh = useCallback(async () => {
    if (userId === undefined) {
      return;
    }

    const query = await juhuu.users.retrieve({
      userId: userId,
    });

    if (query.ok === false) {
      return;
    }

    setUser(query.data.user);
  }, [userId, juhuu]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
        <div className="flex gap-4 items-center">
          <Heading>{user?.name}</Heading>
          <Text>Customer</Text>
        </div>

        <div className="flex items-center gap-4">
          <div className="flex items-center gap-1">
            <Text>{userId}</Text>
          </div>

          {/* <Button
            href={
              "/properties/" + property?.id + "/sessions/" + sessionId + "/edit"
            }
          >
            Edit
          </Button> */}
        </div>
      </div>
      <Divider />
      <div className="flex justify-start flex-row items-center py-4">
        <div className="flex justfy-start flex-col items-start pr-8">
          <Text>Name</Text>
          <Text>{user?.name}</Text>
        </div>
        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>E-mail</Text>
          <Text>{user?.billingEmail}</Text>
        </div>
      </div>
      <Subheading className="mt-8">Rentals & reservations</Subheading>
      <Text>This list contains the rentals & reservations of this user.</Text>
      <Divider className="mb-2" />
      <SessionArray
        sessionListParams={{
          propertyId: property?.id,
          userId: userId,
        }}
      />
    </>
  );
};

export default UserRoute;
