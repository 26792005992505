import React, { useCallback, useEffect, useState } from "react";

import {
    JUHUU,
    LanguageCode,
    LanguageCodeArray as ConstantLanguageCodeArray,
    LocaleString,
} from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useNavigate, useParams } from "react-router-dom";
import { Field } from "../../components/fieldset";
import { Textarea } from "../../components/textarea";
import { Button } from "../../components/button";
import BadgeArticleStatus from "./BadgeStatusArticle";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from "../../components/dropdown";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/16/solid";
import { Text } from "../../components/text";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogDescription,
    DialogTitle,
} from "../../components/dialog";
import {
    Alert,
    AlertActions,
    AlertDescription,
    AlertTitle,
} from "../../components/alert";
import { Navbar, NavbarItem, NavbarSection } from "../../components/navbar";
import FormatLanguageCode from "../../formatters/LanguageCode";
import Markdown from "../../components/markdown";
import { useJUHUU } from "../../context/JuhuuContext";

interface ArticleRouteProps {}

const ArticleRoute: React.FC<ArticleRouteProps> = () => {
    const [article, setArticle] = useState<JUHUU.Article.Object | null>(null);
    const { articleId } = useParams<{ articleId: string }>();
    const [articleUpdateLoading, setArticleUpdateLoading] = useState(false);
    const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState(false);
    const [isOpenPreview, setIsOpenPreview] = useState(false);
    const [markdownContent, setMarkdownContent] = useState<LocaleString | null>(
        null,
    );
    const [currentLanguage, setCurrentLanguage] = useState<LanguageCode>("en");
    const [languageCodeArray, setLanguageCodeArray] = useState<LanguageCode[]>(
        [],
    );
    const [status, setStatus] =
        useState<JUHUU.Article.Object["status"]>("draft");
    const navigate = useNavigate();
    const juhuu = useJUHUU();

    const handleUpdate = useCallback(async () => {
        if (article === null) {
            return;
        }

        if (markdownContent === null) {
            return;
        }

        setArticleUpdateLoading(true);

        const query = await juhuu.articles.update({
            articleId: article.id,
            markdownContent: markdownContent as unknown as LocaleString,
            status: status,
            languageCodeArray: languageCodeArray,
        });

        setArticleUpdateLoading(false);

        if (query.ok === false) {
            return;
        }

        setArticle(query.data.article);
    }, [article, markdownContent, status, languageCodeArray, juhuu]);

    const handleRefresh = useCallback(async () => {
        if (articleId === undefined) {
            return;
        }

        const query = await juhuu.articles.retrieve({
            articleId: articleId,
        });

        if (query.ok === false) {
            return;
        }

        setArticle(query.data.article);
    }, [articleId, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    useEffect(() => {
        if (article === null) {
            return;
        }

        setMarkdownContent(article.markdownContent as unknown as LocaleString);
        setStatus(article.status);
        setLanguageCodeArray(article.languageCodeArray);
        setCurrentLanguage(article.languageCodeArray[0]);
    }, [article]);

    async function handleDeleteArticle() {
        if (article === null) {
            return;
        }

        const query = await juhuu.articles.delete({
            articleId: article.id,
        });

        if (query.ok === false) {
            return;
        }

        setIsOpenDeleteAlert(false);
        navigate("./../../articles");
    }

    function handleRemoveElementFromLanguageCodeArray(
        languageToRemove: LanguageCode,
    ) {
        setLanguageCodeArray(
            languageCodeArray.filter((lc) => {
                if (lc !== languageToRemove) {
                    return true;
                }

                return false;
            }),
        );

        // remove language from markdownContent
        const markdownContentCopy = { ...markdownContent };

        delete markdownContentCopy[languageToRemove];

        setMarkdownContent(markdownContentCopy);
    }

    // handle select current language if the current language is not in the languageCodeArray
    useEffect(() => {
        if (languageCodeArray.includes(currentLanguage) === false) {
            setCurrentLanguage(languageCodeArray[0]);
        }
    }, [languageCodeArray, currentLanguage]);

    return (
        <>
            <div className="flex w-full flex-wrap items-center justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center justify-start">
                    <Heading className="text-nowrap">
                        {article?.title?.[currentLanguage] ?? "Title missing"}
                    </Heading>
                    <Dropdown>
                        <DropdownButton outline>
                            <BadgeArticleStatus status={status} />
                            <ChevronDownIcon />
                        </DropdownButton>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    console.log("draft");
                                    setStatus("draft");
                                }}
                            >
                                Draft
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => {
                                    console.log("published");
                                    setStatus("published");
                                }}
                            >
                                Published
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div className="flex items-center gap-4">
                    <div className="flex items-center gap-4">
                        <Button
                            onClick={() => setIsOpenDeleteAlert(true)}
                            className="text-nowrap"
                            plain
                        >
                            <Text
                                style={{
                                    color: "red",
                                }}
                            >
                                Delete
                            </Text>
                        </Button>
                        <Button
                            onClick={() => setIsOpenPreview(true)}
                            className="text-nowrap"
                            plain
                        >
                            Preview
                        </Button>
                        <Button onClick={handleUpdate}>Save changes</Button>
                    </div>
                </div>
            </div>
            <Navbar>
                <NavbarSection>
                    {languageCodeArray.map((languageCode) => {
                        return (
                            <NavbarItem
                                key={languageCode}
                                onClick={() => setCurrentLanguage(languageCode)}
                                current={currentLanguage === languageCode}
                            >
                                <FormatLanguageCode
                                    languageCode={languageCode}
                                />
                                {languageCodeArray.length > 1 && (
                                    <XCircleIcon
                                        className="w-8 h-8 text-red-500 cursor-pointer"
                                        onClick={() => {
                                            handleRemoveElementFromLanguageCodeArray(
                                                languageCode,
                                            );
                                        }}
                                    />
                                )}
                            </NavbarItem>
                        );
                    })}
                </NavbarSection>
                <NavbarSection>
                    <Dropdown>
                        <DropdownButton outline>
                            Add language
                            <ChevronDownIcon />
                        </DropdownButton>
                        <DropdownMenu>
                            {ConstantLanguageCodeArray.map(
                                (constantLanguageCode) => {
                                    if (
                                        languageCodeArray.includes(
                                            constantLanguageCode,
                                        )
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <DropdownItem
                                            onClick={() => {
                                                setLanguageCodeArray([
                                                    ...languageCodeArray,
                                                    constantLanguageCode,
                                                ]);
                                                setCurrentLanguage(
                                                    constantLanguageCode,
                                                );
                                            }}
                                        >
                                            <FormatLanguageCode
                                                languageCode={
                                                    constantLanguageCode
                                                }
                                            />
                                        </DropdownItem>
                                    );
                                },
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </NavbarSection>
            </Navbar>
            <Field>
                {markdownContent !== null && (
                    <Textarea
                        style={{ height: "600px" }}
                        name="description"
                        disabled={articleUpdateLoading}
                        value={markdownContent[currentLanguage] ?? ""}
                        onChange={(event) => {
                            setMarkdownContent({
                                ...markdownContent,
                                [currentLanguage]: event.target.value,
                            });
                        }}
                    />
                )}
            </Field>

            <Alert open={isOpenDeleteAlert} onClose={setIsOpenDeleteAlert}>
                <AlertTitle>
                    Are you sure you want to delete this article?
                </AlertTitle>
                <AlertDescription>
                    This action cannot be undone.
                </AlertDescription>
                <AlertActions>
                    <Button plain onClick={() => setIsOpenDeleteAlert(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteArticle}>Delete</Button>
                </AlertActions>
            </Alert>

            <Dialog open={isOpenPreview} onClose={setIsOpenPreview} size="5xl">
                <DialogTitle>Preview</DialogTitle>
                <DialogDescription>
                    This is a preview of your article. The article will be
                    visible to everyone once you publish it.
                </DialogDescription>
                <DialogBody>
                    <Markdown
                        markdown={
                            markdownContent !== null
                                ? markdownContent[currentLanguage]
                                : null
                        }
                    />
                </DialogBody>
                <DialogActions>
                    <Button onClick={() => setIsOpenPreview(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ArticleRoute;
/*
amountAuthorized: number; // amount of the article that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the article that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
