import { useCallback, useEffect, useState } from "react";

import { JUHUU } from "@juhuu/sdk-ts";
import { useProperty } from "../../context/PropertyContext";
import { TextLink } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";

interface UserProps {
  user?: JUHUU.User.Object | null;
  userId?: string | null;
}

const User: React.FC<UserProps> = ({
  user: userExternal = null,
  userId: userIdExternal = null,
}) => {
  const [user, setUser] = useState<JUHUU.User.Object | null>(null);
  const juhuu = useJUHUU();
  const { property } = useProperty();

  const handleRefresh = useCallback(async () => {
    if (userExternal !== null) {
      console.log("1");
      setUser(userExternal);
      return;
    }

    if (userIdExternal === null) {
      console.log("3");
      return;
    }

    console.log("4");

    const query = await juhuu.users.retrieve({
      userId: userIdExternal,
    });

    if (query.ok === false) {
      return;
    }

    setUser(query.data.user);
  }, [userExternal, userIdExternal, juhuu]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <TextLink href={"/properties/" + property?.id + "/users/" + user?.id}>
        {user?.name}
      </TextLink>
    </>
  );
};

export default User;
