import { useCallback, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "../../components/table";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { Avatar } from "../../components/avatar";
import { ArrowRightCircleIcon } from "@heroicons/react/16/solid";
import { useJUHUU } from "../../context/JuhuuContext";

interface DeviceTemplateListRouteProps {}

const DeviceTemplateListRoute: React.FC<DeviceTemplateListRouteProps> = () => {
    const [deviceTemplateArray, setDeviceTemplateArray] = useState<
        JUHUU.DeviceTemplate.Object[]
    >([]);
    const juhuu = useJUHUU();

    const { property } = useProperty();
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }
        const query = await juhuu.deviceTemplates.list({
            propertyId: property.id,
        });

        if (query.ok === false) {
            return;
        }

        setDeviceTemplateArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>Device Templates</Heading>
            <Text>
                This list contains all your device templates. A device template
                represents a certain type of device, for example a BikeBox. A
                device template can be used to create multiple devices, that are
                all based on the same template and, thus, behave the same way.
                Device templates can be used to:
            </Text>
            <Text className="mt-1 flex flex-row justify-start items-center gap-2">
                <ArrowRightCircleIcon className="w-5" />
                show a user a certain interface in the JUHUU app while they use
                a device,
            </Text>
            <Text className="mt-1 flex flex-row justify-start items-center gap-2">
                <ArrowRightCircleIcon className="w-5" />
                define the capabilities of a device and how it behaves in the
                JUHUU app,
            </Text>
            <Text className="mt-1 flex flex-row justify-start items-center gap-2">
                <ArrowRightCircleIcon className="w-5" />
                define the properties of a device and it's digital twin, and
                more.
            </Text>
            <Table className="mt-8">
                <TableBody>
                    {deviceTemplateArray.map((deviceTemplate) => {
                        return (
                            <TableRow
                                key={deviceTemplate.id}
                                href={"./" + deviceTemplate.id}
                            >
                                <TableCell className="font-medium flex flex-row justify-start items-center gap-4">
                                    <Avatar
                                        src={deviceTemplate.deviceImageLight}
                                        className="size-12"
                                    />
                                    <Text>{deviceTemplate.name}</Text>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default DeviceTemplateListRoute;
