import React from "react";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import ArticleArray from "./ArticleArray";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import { useJUHUU } from "../../context/JuhuuContext";

interface ArticleListRouteProps {}

const ArticleListRoute: React.FC<ArticleListRouteProps> = () => {
    const { property } = useProperty();
    const navigate = useNavigate();
    const juhuu = useJUHUU();

    async function handleCreateArticle() {
        if (property === null) {
            return;
        }
        const query = await juhuu.articles.create({
            propertyId: property.id,
        });

        if (query.ok === false) {
            return;
        }

        navigate("./" + query.data.article.id);
    }

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>Articles</Heading>
                </div>

                <div className="flex gap-4 items-center">
                    <Button onClick={handleCreateArticle}>
                        Create new article
                    </Button>
                </div>
            </div>

            <Text className="mb-8">
                This list contains the articles you created. Please note that
                all articles are public and can be read by anyone once they are
                published.
            </Text>
            <ArticleArray
                articleListParams={{
                    propertyId: property?.id,
                }}
            />
        </>
    );
};

export default ArticleListRoute;
