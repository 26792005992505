import { useCallback, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { useNavigate } from "react-router-dom";
import { Select } from "../../components/select";
import { ColorScheme, CountryCode } from "@juhuu/sdk-ts";
import { useJUHUU } from "../../context/JuhuuContext";

interface PropertyEditRouteProps {}

const PropertyEditRoute: React.FC<PropertyEditRouteProps> = () => {
  const navigate = useNavigate();
  const { property, setProperty } = useProperty();
  const [propertySaveLoading, setPropertySaveLoading] = useState(false);
  const juhuu = useJUHUU();

  // name
  const [name, setName] = useState<string>("");
  const [legalName, setLegalName] = useState<string>("");

  // billing address
  const [line1, setLine1] = useState<string>("");
  const [line2, setLine2] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<CountryCode>("AT");

  // color scheme
  const [lightPrimary, setLightPrimary] = useState<string>("");
  const [darkPrimary, setDarkPrimary] = useState<string>("");

  const handleUpdate = useCallback(async () => {
    if (property === null) {
      return;
    }

    setPropertySaveLoading(true);
    const query = await juhuu.properties.update({
      propertyId: property.id,
      name: name === "" ? undefined : name,
      legalName: legalName === "" ? undefined : legalName,
      billingAddress: {
        line1: line1 === "" ? undefined : line1,
        line2: line2 === "" ? undefined : line2,
        postalCode: postalCode === "" ? undefined : postalCode,
        city: city === "" ? undefined : city,
        country: country,
      },
      colorScheme: {
        light: {
          primary: lightPrimary === "" ? undefined : lightPrimary,
        },
        dark: {
          primary: darkPrimary === "" ? undefined : darkPrimary,
        },
      } as unknown as ColorScheme,
    });
    setPropertySaveLoading(false);

    if (query.ok === false) {
      return;
    }

    if (query.data.property.type !== "internal") {
      return;
    }

    setProperty(query.data.property);

    navigate(`/properties/${property?.id}`);
  }, [
    property,
    navigate,
    city,
    country,
    line1,
    line2,
    postalCode,
    setProperty,
    name,
    legalName,
    lightPrimary,
    darkPrimary,
    juhuu,
  ]);

  const handleOpenStripe = useCallback(async () => {
    if (property === null) {
      return;
    }

    const query = await juhuu.properties.stripeAccountUrl({
      propertyId: property?.id,
    });

    if (query.ok === false) {
      return;
    }

    window.open(query.data.url, "_blank");
  }, [property, juhuu]);

  return (
    <>
      <Heading>Company settings</Heading>
      <Divider className="mb-4" />
      <Fieldset className="mb-8">
        <FieldGroup>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>Name</Label>
              <Description>
                The name of your company that will be visible to your users.
              </Description>
            </div>
            <div className="w-1/2">
              <Input
                defaultValue={property?.name}
                disabled={propertySaveLoading}
                placeholder="JUHUU"
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          </Field>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>Legal name</Label>

              <Description>
                The of your company name we use on the invoices we create on
                your behalf.
              </Description>
            </div>
            <div className="w-1/2">
              <Input
                defaultValue={property?.legalName}
                disabled={propertySaveLoading}
                placeholder="JUHUU Mobility Ltd."
                onChange={(event) => setLegalName(event.target.value)}
              />
            </div>
          </Field>
        </FieldGroup>
      </Fieldset>
      <Subheading>Billing information</Subheading>
      <Divider className="mb-4" />

      <Fieldset className="mb-8">
        <FieldGroup>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>Payment service provider setup</Label>
              <Description>
                JUHUU partners with Stripe for secure payments and financial
                services. Open Stripe's settings to setup the bank account you
                want to receive your payouts to.
              </Description>
            </div>
            <div className="w-1/2">
              <Button onClick={handleOpenStripe}>Open Stripe's settings</Button>
            </div>
          </Field>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>Address line 1</Label>
            </div>
            <div className="w-1/2">
              <Input
                defaultValue={property?.billingAddress?.line1}
                disabled={propertySaveLoading}
                placeholder="Schrammelgasse 79"
                onChange={(event) => setLine1(event.target.value)}
              />
            </div>
          </Field>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>Address line 2</Label>
            </div>
            <div className="w-1/2">
              <Input
                defaultValue={property?.billingAddress?.line2 || ""}
                disabled={propertySaveLoading}
                onChange={(event) => setLine2(event.target.value)}
              />
            </div>
          </Field>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>Postal code</Label>
            </div>
            <div className="w-1/2">
              <Input
                placeholder="1170"
                defaultValue={property?.billingAddress?.postalCode}
                disabled={propertySaveLoading}
                onChange={(event) => setPostalCode(event.target.value)}
              />
            </div>
          </Field>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>City</Label>
            </div>
            <div className="w-1/2">
              <Input
                placeholder="Wien"
                defaultValue={property?.billingAddress?.city}
                disabled={propertySaveLoading}
                onChange={(event) => setCity(event.target.value)}
              />
            </div>
          </Field>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>Country</Label>
              <Description>
                If your country does not show up in the list, please contact us.
              </Description>
            </div>
            <div className="w-1/2">
              <Select
                disabled={propertySaveLoading}
                defaultValue={property?.billingAddress?.country}
                onChange={(event) =>
                  setCountry(event.target.value as CountryCode)
                }
              >
                <option value={"AT"}>Austria</option>
                <option value={"DE"}>Germany</option>
                <option value={"CH"}>Switzerland</option>
                <option value={"LI"}>Liechtenstein</option>
                <option value={"IT"}>Italy</option>
                <option value={"FR"}>France</option>
                <option value={"NL"}>Netherlands</option>
                <option value={"BE"}>Belgium</option>
                <option value={"LU"}>Luxembourg</option>
                <option value={"DK"}>Denmark</option>
                <option value={"SE"}>Sweden</option>
                <option value={"NO"}>Norway</option>
                <option value={"FI"}>Finland</option>
                <option value={"IS"}>Iceland</option>
                <option value={"GB"}>Great Britain</option>
                <option value={"IE"}>Ireland</option>
                <option value={"ES"}>Spain</option>
                <option value={"PT"}>Portugal</option>
                <option value={"GR"}>Greece</option>
                <option value={"PL"}>Poland</option>
                <option value={"CZ"}>Czech Republic</option>
                <option value={"SK"}>Slovakia</option>
                <option value={"HU"}>Hungary</option>
                <option value={"SI"}>Slovenia</option>
                <option value={"HR"}>Croatia</option>
                <option value={"BA"}>Bosnia and Herzegovina</option>
                <option value={"RS"}>Serbia</option>
                <option value={"US"}>United States</option>
                <option value={"CA"}>Canada</option>
              </Select>
            </div>
          </Field>
        </FieldGroup>
      </Fieldset>

      <Subheading>Color scheme</Subheading>
      <Divider className="mb-4" />

      <Fieldset className="mb-8">
        <FieldGroup>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>Primary light mode</Label>
              <Description>
                The primary color of your company that our platform shows to
                your users if their device is set to light mode.
              </Description>
            </div>
            <div className="w-1/2">
              <Input
                defaultValue={property?.colorScheme.light.primary}
                disabled={propertySaveLoading}
                placeholder="#6e17fa"
                onChange={(event) => setLightPrimary(event.target.value)}
              />
            </div>
          </Field>
          <Field className="flex flex-row justify-between gap-4">
            <div className="w-1/2">
              <Label>Primary dark mode</Label>
              <Description>
                The primary color of your company that our platform shows to
                your users if their device is set to dark mode.
              </Description>
            </div>
            <div className="w-1/2">
              <Input
                defaultValue={property?.colorScheme.dark.primary}
                disabled={propertySaveLoading}
                placeholder="#ae7dfc"
                onChange={(event) => setDarkPrimary(event.target.value)}
              />
            </div>
          </Field>
        </FieldGroup>
      </Fieldset>

      <Divider className="mb-4" />
      <div className="flex justify-end gap-4">
        <Button onClick={() => navigate(-1)} plain>
          Cancel
        </Button>
        <Button onClick={handleUpdate}>Save changes</Button>
      </div>
    </>
  );
};

export default PropertyEditRoute;
