import React, { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import Timestamp from "../../formatters/Timestamp";
import { Badge } from "../../components/badge";
import BadgeSessionStatus from "./BadgeSessionStatus";
import { useJUHUU } from "../../context/JuhuuContext";

interface SessionArrayProps {
  sessionListParams: JUHUU.Session.List.Params;
}

const SessionArray: React.FC<SessionArrayProps> = ({ sessionListParams }) => {
  const [sessionArray, setSessionArray] = useState<JUHUU.Session.Object[]>([]);
  const juhuu = useJUHUU();

  const handleRefresh = useCallback(async () => {
    const query = await juhuu.sessions.list(sessionListParams);

    if (query.ok === false) {
      return;
    }

    setSessionArray(query.data);
  }, [sessionListParams, juhuu]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>Tariff</TableHeader>
          <TableHeader>Created</TableHeader>
          <TableHeader>Status</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {sessionArray.map((session) => {
          const date = new Date(session.createdAt);
          const isNew = date.getTime() > Date.now() - 1000 * 60 * 60 * 24 * 7;
          return (
            <TableRow
              key={session.id}
              href={
                "/properties/" + session.propertyId + "/sessions/" + session.id
              }
            >
              <TableCell>{session.tariff.name.en} </TableCell>
              <TableCell>
                <Timestamp timestamp={session.createdAt} />
                {isNew === true && (
                  <Badge color="lime" className="ml-2">
                    New
                  </Badge>
                )}
              </TableCell>
              <TableCell>
                <BadgeSessionStatus status={session.status} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SessionArray;
