import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";

interface AccountingAreaListRouteProps {}

const AccountingAreaListRoute: React.FC<AccountingAreaListRouteProps> = () => {
    const [accountingAreaArray, setAccountingAreaArray] = useState<
        JUHUU.AccountingArea.Object[]
    >([]);
    const juhuu = useJUHUU();
    const { property } = useProperty();
    const handleRefresh = useCallback(async () => {
        if (property?.id === undefined) {
            return;
        }

        const query = await juhuu.accountingAreas.list({
            propertyId: property?.id,
        });

        if (query.ok === false) {
            return;
        }

        setAccountingAreaArray(query.data);
    }, [property, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    return (
        <>
            <Heading>AccountingAreas</Heading>
            <Text className="mb-8">
                Setup accountingAreas to group your payments into regions. Once
                a payout is created, payments are grouped by accountingAreas on
                the credit note. This might be useful for accounting purposes.
            </Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accountingAreaArray.map((accountingArea) => {
                        return (
                            <TableRow key={accountingArea.id}>
                                <TableCell>{accountingArea.name} </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default AccountingAreaListRoute;
