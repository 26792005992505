import React, { useCallback, useEffect, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Dialog } from "./dialog";
import ChatArray from "../routes/chats/ChatArray";
import { useUser } from "../context/UserContext";
import {
    ArrowLeftCircleIcon,
    PaperAirplaneIcon,
    PlusCircleIcon,
    XCircleIcon,
} from "@heroicons/react/16/solid";
import { Heading } from "./heading";
import { Input } from "./input";
import { Text } from "./text";
import Timestamp from "../formatters/Timestamp";
import { useJUHUU } from "../context/JuhuuContext";

interface BuddyDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const BuddyDialogInput: React.FC<BuddyDialogProps> = ({ isOpen, onClose }) => {
    const { user } = useUser();

    const [selectedChat, setSelectedChat] = useState<JUHUU.Chat.Object | null>(
        null,
    );
    const juhuu = useJUHUU();
    const [message, setMessage] = useState<string>("");

    const [chatMessageArray, setChatMessageArray] = useState<
        JUHUU.ChatMessage.Object[]
    >([]);

    function handleClose() {
        setSelectedChat(null);
        setChatMessageArray([]);
        onClose();
    }

    const handleListChatMessageArray = useCallback(async () => {
        if (selectedChat === null) {
            return;
        }

        const query = await juhuu.chatMessages.list(
            {
                chatId: selectedChat.id,
            },
            {
                limit: 10,
            },
        );

        if (query.ok === false) {
            return;
        }

        setChatMessageArray(query.data.chatMessageArray.reverse());
    }, [selectedChat, juhuu]);

    useEffect(() => {
        handleListChatMessageArray();
    }, [handleListChatMessageArray]);

    async function handleCreateChat() {
        if (user === null) {
            return;
        }

        console.log("handleCreateNewChat");
        const query = await juhuu.chats.create({
            userId: user.id,
        });

        if (query.ok === false) {
            return;
        }

        setSelectedChat(query.data.chat);
    }

    async function handleCreateMessage() {
        if (selectedChat === null || user === null) {
            return;
        }

        const messageCreateQuery = await juhuu.chatMessages.create({
            chatId: selectedChat.id,
            message: message,
            userId: user.id,
        });

        if (messageCreateQuery.ok === false) {
            return;
        }

        setChatMessageArray([
            ...chatMessageArray,
            messageCreateQuery.data.chatMessage,
        ]);

        setMessage("");

        const completeChatQuery = await juhuu.chats.complete({
            chatId: selectedChat.id,
        });

        if (completeChatQuery.ok === false) {
            return;
        }

        setSelectedChat(completeChatQuery.data.chat);
        setChatMessageArray(completeChatQuery.data.chatMessageArray.reverse());
    }

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose} size="3xl">
                <div
                    style={{
                        height: "calc(100vh - 300px)",
                        display: "flex",
                        flexDirection: "column", // Flex container for the entire content
                    }}
                >
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row justify-between items-start gap-2">
                            {selectedChat !== null && (
                                <ArrowLeftCircleIcon
                                    className="w-8 h-8 text-zinc-300 cursor-pointer"
                                    onClick={() => {
                                        setSelectedChat(null);
                                        setChatMessageArray([]);
                                    }}
                                />
                            )}
                            <Heading>
                                {selectedChat === null
                                    ? "Your chats"
                                    : selectedChat.title}
                            </Heading>
                        </div>
                        <div className="flex flex-row justify-between items-start gap-2">
                            {selectedChat === null && (
                                <PlusCircleIcon
                                    className="w-8 h-8 text-black cursor-pointer"
                                    onClick={handleCreateChat}
                                />
                            )}

                            <XCircleIcon
                                className="w-8 h-8 text-red-500 cursor-pointer"
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                    <div className="mt-4 flex flex-grow flex-col justify-start">
                        {selectedChat === null ? (
                            <ChatArray
                                onSelectedChat={setSelectedChat}
                                chatListParams={{
                                    userId: user?.id,
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    flexGrow: 1, // Let the chat messages grow to fill the available space
                                }}
                            >
                                <div
                                    style={{
                                        flexGrow: 1,
                                        overflowY: "auto", // Add scroll for the message array
                                        maxHeight: "calc(100vh - 400px)",
                                    }}
                                    className="rounded-2xl"
                                >
                                    {chatMessageArray.map((chatMessage) => {
                                        return (
                                            <div
                                                key={chatMessage.id}
                                                className={`flex flex-row items-center ${
                                                    chatMessage.type === "ai"
                                                        ? "justify-start"
                                                        : "justify-end"
                                                }`}
                                            >
                                                <div
                                                    className={`width-1/2 p-2 ${
                                                        chatMessage.type ===
                                                        "ai"
                                                            ? "bg-zinc-200"
                                                            : "bg-lime-400"
                                                    } rounded-2xl mb-2`}
                                                >
                                                    <Text
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        {chatMessage.type ===
                                                        "ai"
                                                            ? chatMessage.translatedMessage
                                                            : chatMessage.originalMessage}
                                                    </Text>
                                                    {chatMessage.type ===
                                                        "ai" &&
                                                        chatMessage
                                                            ?.articleEmbeddingArray
                                                            ?.length > 0 && (
                                                            <Text className="mt-2">
                                                                JUHUU Buddy used
                                                                the following
                                                                sources:
                                                            </Text>
                                                        )}
                                                    <div className="flex flex-row flex-wrap gap-2">
                                                        {chatMessage.type ===
                                                            "ai" &&
                                                            chatMessage.articleEmbeddingArray.map(
                                                                (
                                                                    articleEmbedding,
                                                                    index,
                                                                ) => {
                                                                    if (
                                                                        index >
                                                                        2
                                                                    ) {
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <div
                                                                            className="h-12 w-44 border-2 border-zinc-300 bg-white rounded-lg py-1 px-2 hover:bg-zinc-100 cursor-pointer"
                                                                            onClick={() =>
                                                                                window.open(
                                                                                    "https://docs.juhuu.app/articles/" +
                                                                                        articleEmbedding.articleId +
                                                                                        "?positionFrom=" +
                                                                                        articleEmbedding.lineFrom +
                                                                                        "&positionTo=" +
                                                                                        articleEmbedding.lineTo,
                                                                                    "_blank",
                                                                                )
                                                                            }
                                                                        >
                                                                            <Text
                                                                                style={{
                                                                                    color: "#000",
                                                                                    fontSize:
                                                                                        "0.75rem",
                                                                                }}
                                                                            >
                                                                                Article
                                                                            </Text>
                                                                            <Text
                                                                                style={{
                                                                                    fontSize:
                                                                                        "0.75rem",
                                                                                    position:
                                                                                        "relative",
                                                                                    top: "-10px",
                                                                                }}
                                                                                className="truncate"
                                                                            >
                                                                                ...
                                                                                {
                                                                                    articleEmbedding.chunk
                                                                                }
                                                                                ...
                                                                            </Text>
                                                                        </div>
                                                                    );
                                                                },
                                                            )}
                                                    </div>
                                                    <Text
                                                        style={{
                                                            fontSize: "0.75rem",
                                                            textAlign:
                                                                chatMessage.type ===
                                                                "ai"
                                                                    ? "left"
                                                                    : "right",
                                                        }}
                                                    >
                                                        <Timestamp
                                                            timestamp={
                                                                chatMessage.createdAt
                                                            }
                                                            display="minutes"
                                                        />
                                                        <span className="opacity-0 hover:opacity-100	">
                                                            {" - Message ID: " +
                                                                chatMessage.id}
                                                        </span>
                                                    </Text>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                {/* Input at the bottom */}
                                <div className="flex items-center w-full gap-4 mt-4">
                                    <Input
                                        placeholder="Ask JUHUU Buddy anything..."
                                        value={message}
                                        onChange={(event) =>
                                            setMessage(event.target.value)
                                        }
                                    />
                                    <PaperAirplaneIcon
                                        className="h-6 cursor-pointer"
                                        onClick={() => handleCreateMessage()}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default BuddyDialogInput;
