import React from "react";
import { Heading } from "../components/heading";

interface ErrorRouteProps {}

const ErrorRoute: React.FC<ErrorRouteProps> = () => {
  return (
    <>
      <Heading>This page could not be found.</Heading>
    </>
  );
};

export default ErrorRoute;
