import { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";

interface TermListRouteProps {}

const TermListRoute: React.FC<TermListRouteProps> = () => {
  const [termArray, setTermArray] = useState<JUHUU.Term.Object[]>([]);
  const juhuu = useJUHUU();
  const { property } = useProperty();

  const handleRefresh = useCallback(async () => {
    if (property?.id === undefined) {
      return;
    }

    const query = await juhuu.terms.list({
      propertyId: property?.id,
    });

    if (query.ok === false) {
      return;
    }

    setTermArray(query.data);
  }, [property, juhuu]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <Heading>Terms</Heading>
      <Text className="mb-8">
        Setup terms that your users need to accept before they can create a
        rental or reservation.
      </Text>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {termArray.map((term) => {
            return (
              <TableRow key={term.id}>
                <TableCell>{term.name} </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default TermListRoute;
