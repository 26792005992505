import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useCallback,
} from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { useLocation } from "react-router-dom";
import { useJUHUU } from "./JuhuuContext";

const PropertyContext = createContext<{
  property: JUHUU.Property.Internal | null;
  setProperty: React.Dispatch<
    React.SetStateAction<JUHUU.Property.Internal | null>
  >;
}>({
  property: null,
  setProperty: () => {},
});

export function useProperty() {
  return useContext(PropertyContext);
}

interface PropertyProviderProps {
  children?: React.ReactNode;
}

const PropertyProvider: React.FC<PropertyProviderProps> = ({
  children = null,
}) => {
  const [property, setProperty] = useState<JUHUU.Property.Internal | null>(
    null
  );

  const juhuu = useJUHUU();

  const location = useLocation();

  const handleRefresh =
    useCallback(async (): Promise<JUHUU.Property.Internal | null> => {
      console.log("refreshing property context");
      const localStoragePropertyId = location.pathname.split("/")[2];

      if (localStoragePropertyId === undefined) {
        setProperty(null);
        return null;
      }

      const query = await juhuu.properties.retrieve({
        propertyId: localStoragePropertyId,
      });

      if (!query.ok) {
        return null;
      }

      if (query.data.property.type !== "internal") {
        return null;
      }

      setProperty(query.data.property);

      return query.data.property;
    }, [location, juhuu]);

  useEffect(() => {
    console.log("refreshing property document");
    handleRefresh();
  }, [handleRefresh]);

  return (
    <PropertyContext.Provider
      value={{
        property,
        setProperty,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

export default PropertyProvider;
