import { JUHUU, Juhuu } from "@juhuu/sdk-ts";
import React, { useContext, createContext } from "react";

export const JuhuuContext = createContext<Juhuu>({} as Juhuu);

export function useJUHUU() {
  return useContext(JuhuuContext);
}

interface JuhuuProviderProps {
  children?: React.ReactNode;
  config: JUHUU.SetupConfig;
}

const JuhuuProvider: React.FC<JuhuuProviderProps> = ({
  children = null,
  config,
}) => {
  const value: Juhuu = new Juhuu(config);

  return (
    <JuhuuContext.Provider value={value}>{children}</JuhuuContext.Provider>
  );
};

export default JuhuuProvider;
