import { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text, TextLink } from "../../components/text";
import { Avatar } from "../../components/avatar";
import { Navbar, NavbarItem, NavbarSection } from "../../components/navbar";
import { GoogleMap, LoadScriptNext, OverlayView } from "@react-google-maps/api";
import { Button } from "../../components/button";
import { useJUHUU } from "../../context/JuhuuContext";

interface PointsListRouteProps {}

const PointsListRoute: React.FC<PointsListRouteProps> = () => {
  const [pointArray, setPointArray] = useState<JUHUU.Point.Single[]>([]);
  const [display, setDisplay] = useState<"table" | "map">("map");
  const [averageLat, setAverageLat] = useState<number>(0);
  const [averageLong, setAverageLong] = useState<number>(0);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const juhuu = useJUHUU();

  const { property } = useProperty();
  const handleRefresh = useCallback(async () => {
    if (property?.id === undefined) {
      return;
    }
    const query = await juhuu.points.list({
      propertyId: property.id,
    });

    if (query.ok === false) {
      return;
    }

    // calculate average lat and average long
    let sumLat = 0;
    let sumLong = 0;
    query.data.forEach((point) => {
      sumLat += point.location.coordinates[1];
      sumLong += point.location.coordinates[0];
    });

    setAverageLat(sumLat / query.data.length);
    setAverageLong(sumLong / query.data.length);

    setPointArray(
      query.data.filter(
        (point) => point.type === "single"
      ) as JUHUU.Point.Single[]
    );
  }, [property, juhuu]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const getPixelPositionOffset = (width: number, height: number) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  return (
    <>
      <Heading>Markers</Heading>
      <Text className="mb-8">
        This list contains all of your map-markers. These markers are displayed
        on the map of the JUHUU App. If a user clicks on one of them, they are
        forwarded to one of your{" "}
        <TextLink href="./../locations">locations</TextLink>.
      </Text>
      <Navbar>
        <NavbarSection className="max-lg:hidden">
          <NavbarItem
            current={display === "map"}
            onClick={() => setDisplay("map")}
          >
            Map
          </NavbarItem>
          <NavbarItem
            current={display === "table"}
            onClick={() => setDisplay("table")}
          >
            Table
          </NavbarItem>
        </NavbarSection>
      </Navbar>
      {display === "table" && (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>Name</TableHeader>
              <TableHeader>Forwards to</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {pointArray.map((point) => {
              return (
                <TableRow key={point.id}>
                  {/*href={"./" + location.id}*/}
                  <TableCell className="font-medium flex flex-row justify-start items-center gap-4">
                    <Avatar src={point.iconLight} className="size-12" />
                  </TableCell>
                  <TableCell>
                    <Button href={"./../locations/" + point.referenceObjectId}>
                      Go to location
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}

      {display === "map" && (
        <LoadScriptNext
          googleMapsApiKey="AIzaSyBhqDFMAGPGH-QyoTJL55RAobduqqdaPeI"
          preventGoogleFontsLoading={true}
          onLoad={() => {
            setMapLoaded(true);
          }}
          onUnmount={() => {
            setMapLoaded(false);
          }}
        >
          <GoogleMap
            mapContainerStyle={{
              height: "70vh",
              width: "100%",
              borderRadius: "15px",
              overflow: "hidden",
              marginTop: "10px",
            }}
            center={{
              lat: averageLat,
              lng: averageLong,
            }}
            zoom={10}
          >
            {mapLoaded === true &&
              pointArray.map((point) => {
                return (
                  // <Marker
                  //   key={point.id}
                  //   position={{
                  //     lat: point.location.coordinates[1],
                  //     lng: point.location.coordinates[0],
                  //   }}
                  //   icon={{
                  //     url: point.iconLight ?? "",
                  //     scaledSize: new window.google.maps.Size(30, 30),
                  //   }}
                  // />
                  <OverlayView
                    position={{
                      lat: point.location.coordinates[1],
                      lng: point.location.coordinates[0],
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={getPixelPositionOffset}
                  >
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        transform: "translate(-50%, -50%)",
                        background: "white",
                        padding: "5px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      {/* You can place custom JSX here */}
                      <img
                        src={point.iconLight ?? ""}
                        alt="custom-icon"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </div>
                  </OverlayView>
                );
              })}
          </GoogleMap>
        </LoadScriptNext>
      )}
    </>
  );
};

export default PointsListRoute;
