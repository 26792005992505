import React from "react";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { Button } from "../../components/button";
import SessionArray from "./SessionArray";
import { useJUHUU } from "../../context/JuhuuContext";

interface SessionListRouteProps {}

const SessionListRoute: React.FC<SessionListRouteProps> = () => {
  const { property } = useProperty();
  const juhuu = useJUHUU();

  const handleSessionExport = async () => {
    console.log("Exporting sessions");
    if (property?.id === undefined) {
      return;
    }

    const query = await juhuu.sessions.export({
      propertyId: property?.id,
      outputType: "csv",
    });

    if (query.ok === false) {
      return;
    }

    window.open(query.data.outputUrl, "_blank");
  };

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4">
        <Heading>Rentals and Reservations of your users</Heading>

        <Button onClick={handleSessionExport}>Export</Button>
      </div>
      <Text className="mb-8">
        Everytime a user completes a rental or reservation it shows up in this
        list.
      </Text>
      <SessionArray
        sessionListParams={{
          propertyId: property?.id,
        }}
      />
    </>
  );
};

export default SessionListRoute;
