import { useCallback, useEffect, useState } from "react";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Divider } from "../../components/divider";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";
import {
    addEdge,
    Background,
    Connection,
    Controls,
    ReactFlow,
    useEdgesState,
    useNodesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import NodeFlowStart from "./NodeFlowStart";
import { useJUHUU } from "../../context/JuhuuContext";

const defaultNodes = [
    {
        id: "1",
        position: { x: 0, y: 0 },
        data: { label: "1" },
        type: "custom",
    },
    {
        id: "2",
        position: { x: 0, y: 100 },
        data: { label: "2" },
    },
];
const defaultEdges = [
    {
        id: "e1-2",
        source: "1",
        target: "2",
    },
];

interface DeviceTemplateRouteProps {}

const DeviceTemplateRoute: React.FC<DeviceTemplateRouteProps> = () => {
    const [deviceTemplate, setDeviceTemplate] =
        useState<JUHUU.DeviceTemplate.Object | null>(null);
    const { deviceTemplateId } = useParams<{ deviceTemplateId: string }>();
    const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(defaultEdges);
    const { property } = useProperty();
    const juhuu = useJUHUU();

    const handleRefresh = useCallback(async () => {
        if (property === null) {
            return;
        }

        if (deviceTemplateId === undefined) {
            return;
        }

        const deviceTemplateQueryPromise = juhuu.deviceTemplates.retrieve({
            deviceTemplateId: deviceTemplateId,
        });

        const deviceTemplateQuery = await deviceTemplateQueryPromise;

        if (deviceTemplateQuery.ok === false) {
            return;
        }

        setDeviceTemplate(deviceTemplateQuery.data.deviceTemplate);

        // create initial nodes and edges
        const initialEdges: Array<{
            id: string;
            source: string;
            target: string;
            sourceHandle?: string;
        }> = [];

        const initialNodes: Array<{
            id: string;
            position: {
                x: number;
                y: number;
            };
            data: {
                label: string;
            };
            type: string;
        }> = deviceTemplateQuery.data.deviceTemplate.nodeArray.map(
            (node, index) => {
                // create edge
                if (
                    node.type !== "flow.end" &&
                    node.type !== "flow.if" &&
                    node.type !== "flow.exception"
                ) {
                    node.nodeIdArray.forEach((nodeId) => {
                        initialEdges.push({
                            id: `${node.id}-${nodeId}`,
                            source: node.id,
                            target: nodeId,
                        });
                    });
                }

                if (node.type === "flow.if") {
                    node.trueNodeIdArray.forEach((nodeId) => {
                        initialEdges.push({
                            id: `true-${node.id}-${nodeId}`,
                            source: node.id,
                            sourceHandle: "true",
                            target: nodeId,
                        });
                    });

                    node.falseNodeIdArray.forEach((nodeId) => {
                        initialEdges.push({
                            id: `false-${node.id}-${nodeId}`,
                            source: node.id,
                            sourceHandle: "false",
                            target: nodeId,
                        });
                    });
                }

                return {
                    type: "custom",
                    id: node.id,
                    position: { x: 0, y: index * -100 },
                    data: { label: "1", node: node },
                };
            },
        );

        setNodes(initialNodes);
        setEdges(initialEdges);
    }, [deviceTemplateId, property, setNodes, setEdges, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const onConnect = useCallback(
        (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges],
    );

    const nodeTypes = {
        custom: NodeFlowStart, // Register your custom node here
    };

    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
                <div className="flex gap-4 items-center">
                    <Heading>{deviceTemplate?.name}</Heading>
                    <Text>Device Template</Text>
                </div>

                <div className="flex items-center gap-4">
                    <div className="flex items-center gap-1">
                        <Text>{deviceTemplateId}</Text>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="flex justify-start flex-row items-center py-4">
                <div className="flex justfy-start flex-col items-start pr-8">
                    <Text>Call to action</Text>
                    <Text>"{deviceTemplate?.callToAction.en}"</Text>
                </div>
            </div>
            <Subheading className="mt-6">Parameters</Subheading>
            <Divider className="mb-2" />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Default value</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deviceTemplate?.parameterArray.map((parameter) => {
                        return (
                            <TableRow key={parameter.name}>
                                <TableCell>
                                    <Text>{parameter.name}</Text>
                                </TableCell>
                                <TableCell>{parameter.current}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Subheading className="mt-8">Commands</Subheading>
            <Divider className="mb-2" />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Value</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deviceTemplate?.commandArray.map((command) => {
                        return (
                            <TableRow key={command.name}>
                                <TableCell>
                                    <Text>{command.name}</Text>
                                </TableCell>
                                <TableCell>{command.type}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Subheading className="mt-8">Flows</Subheading>
            <Text>
                Flows allow you to tell your device how it should behave and
                what should happen in various scenarios and events.
            </Text>
            <Divider className="mb-8" />
            <div
                style={{
                    height: "600px",
                }}
                className="border border-gray-200 rounded-xl"
            >
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    fitView={true}
                    nodeTypes={nodeTypes}
                >
                    <Controls />
                    {/* <MiniMap /> */}
                    {/* <Panel position="top-left">top-left</Panel>
          <Panel position="top-center">top-center</Panel>
          <Panel position="top-right">top-right</Panel>
          <Panel position="bottom-left">bottom-left</Panel>
          <Panel position="bottom-center">bottom-center</Panel>
          <Panel position="bottom-right">bottom-right</Panel> */}
                    <Background patternClassName="dots" gap={12} size={1} />
                </ReactFlow>
            </div>
        </>
    );
};

export default DeviceTemplateRoute;
