import { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";

import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import { Avatar } from "../../components/avatar";
import Utilization from "../../formatters/BadgeUtilization";
import LocationType from "./LocationType";
import { useJUHUU } from "../../context/JuhuuContext";

interface LocationListRouteProps {}

const LocationListRoute: React.FC<LocationListRouteProps> = () => {
  const [locationArray, setLocationArray] = useState<JUHUU.Location.Object[]>(
    []
  );
  const juhuu = useJUHUU();
  const { property } = useProperty();
  const handleRefresh = useCallback(async () => {
    if (property?.id === undefined) {
      return;
    }

    const query = await juhuu.locations.list({
      propertyId: property.id,
      rentableDeviceGroupLocationId: "null",
    });

    if (query.ok === false) {
      return;
    }
    setLocationArray(query.data);
  }, [property, juhuu]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <Heading>Locations</Heading>
      <Text className="mb-8">This list contains your locations.</Text>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Utilization</TableHeader>
            <TableHeader>Type</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {locationArray.map((location) => {
            return (
              <TableRow key={location.id} href={"./" + location.id}>
                {/*href={"./" + location.id}*/}
                <TableCell className="font-medium flex flex-row justify-start items-center gap-4">
                  <Avatar src={location.iconLight} className="size-12" />
                  <Text>{location.name}</Text>
                </TableCell>
                <TableCell>
                  <Utilization location={location} />
                </TableCell>
                <TableCell>
                  <LocationType type={location.type} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default LocationListRoute;
