import { useCallback, useEffect, useState } from "react";

import { JUHUU } from "@juhuu/sdk-ts";
import { useProperty } from "../../context/PropertyContext";
import { Strong, Text, TextLink } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";

interface LicenseTemplateProps {
  licenseTemplate?: JUHUU.LicenseTemplate.Object | null;
  licenseTemplateId?: string | null;
}

const LicenseTemplate: React.FC<LicenseTemplateProps> = ({
  licenseTemplate: licenseTemplateExternal = null,
  licenseTemplateId: licenseTemplateIdExternal = null,
}) => {
  const [licenseTemplate, setLicenseTemplate] =
    useState<JUHUU.LicenseTemplate.Object | null>(null);
  const juhuu = useJUHUU();
  const { property } = useProperty();

  const handleRefresh = useCallback(async () => {
    if (licenseTemplateExternal !== null) {
      console.log("1");
      setLicenseTemplate(licenseTemplateExternal);
      return;
    }

    if (licenseTemplateIdExternal === null) {
      console.log("3");
      return;
    }

    console.log("4");

    const query = await juhuu.licenseTemplates.retrieve({
      licenseTemplateId: licenseTemplateIdExternal,
    });

    if (query.ok === false) {
      return;
    }

    setLicenseTemplate(query.data.licenseTemplate);
  }, [licenseTemplateExternal, licenseTemplateIdExternal, juhuu]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <Strong>
        <TextLink
          href={
            "/properties/" +
            property?.id +
            "/licenseTemplates/" +
            licenseTemplate?.id
          }
        >
          {licenseTemplate?.name.en}
        </TextLink>
      </Strong>
      <Text>{licenseTemplate?.description.en}</Text>
    </>
  );
};

export default LicenseTemplate;
