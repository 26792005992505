import React, { useCallback, useEffect, useMemo, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/table";

import { Text } from "../../components/text";
import BadgeDeviceStatus from "./BadgeStatusDevice";
import {
    Pagination,
    PaginationGap,
    PaginationList,
    PaginationNext,
    PaginationPage,
    PaginationPrevious,
} from "../../components/pagination";
import { useJUHUU } from "../../context/JuhuuContext";

interface DeviceArrayProps {
    deviceListParams?: JUHUU.Device.List.Params;
    deviceIdArray?: string[];
}

const DeviceArray: React.FC<DeviceArrayProps> = ({
    deviceListParams,
    deviceIdArray,
}) => {
    const [deviceArray, setDeviceArray] = useState<JUHUU.Device.Object[]>([]);
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const stepSize = 10;
    const pageGapThreshold = 5;
    const juhuu = useJUHUU();

    const maxPages = useMemo(() => {
        return Math.ceil(count / stepSize);
    }, [count, stepSize]);

    const handleRefresh = useCallback(async () => {
        if (deviceListParams !== undefined) {
            const query = await juhuu.devices.list(deviceListParams, {
                limit: stepSize,
                skip: (page - 1) * stepSize,
            });

            if (query.ok === false) {
                return;
            }

            setDeviceArray(query.data.deviceArray);
            setCount(query.data.count);
        } else if (deviceIdArray !== undefined) {
            console.log("deviceIdArray", deviceIdArray);
            // fetch devices by id
            const queryPromiseArray: Promise<
                JUHUU.HttpResponse<JUHUU.Device.Retrieve.Response>
            >[] = [];

            deviceIdArray.forEach(async (deviceId) => {
                queryPromiseArray.push(
                    juhuu.devices.retrieve({
                        deviceId: deviceId,
                    }),
                );
            });

            setCount(deviceIdArray.length);

            const queryArray = await Promise.all(queryPromiseArray);

            const tempDeviceArray: JUHUU.Device.Object[] = [];

            queryArray.forEach((query) => {
                if (query.ok === true) {
                    tempDeviceArray.push(query.data.device);
                }
            });

            setDeviceArray(tempDeviceArray);
        }
    }, [deviceListParams, deviceIdArray, page, stepSize, juhuu]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const renderPagination = () => {
        const paginationItems = [];

        // Less than or equal to pageGapThreshold pages, show all pages
        if (maxPages <= pageGapThreshold) {
            for (let index = 0; index < maxPages; index++) {
                paginationItems.push(
                    <PaginationPage
                        key={index}
                        onClick={() => setPage(index + 1)}
                        current={index + 1 === page}
                    >
                        {index + 1}
                    </PaginationPage>,
                );
            }
        } else {
            for (
                let index = 0;
                index < Math.min(pageGapThreshold - 1, maxPages);
                index++
            ) {
                paginationItems.push(
                    <PaginationPage
                        key={index}
                        onClick={() => setPage(index + 1)}
                        current={index + 1 === page}
                    >
                        {index + 1}
                    </PaginationPage>,
                );
            }

            if (maxPages > pageGapThreshold) {
                paginationItems.push(<PaginationGap key="gap" />);
                paginationItems.push(
                    <PaginationPage
                        key={maxPages - 1}
                        onClick={() => setPage(maxPages)}
                        current={maxPages === page}
                    >
                        {maxPages}
                    </PaginationPage>,
                );
            }
        }

        return paginationItems;
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Status</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deviceArray.map((device) => {
                        return (
                            <TableRow
                                key={device.id}
                                href={
                                    "/properties/" +
                                    device.propertyId +
                                    "/devices/" +
                                    device.id
                                }
                            >
                                <TableCell>
                                    <Text>{device.name}</Text>
                                </TableCell>
                                <TableCell>
                                    <BadgeDeviceStatus status={device.status} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {maxPages > 1 && (
                <Pagination className="mt-8">
                    <PaginationPrevious
                        onClick={() => setPage(Math.max(0, page - 1))}
                        disabled={page === 1}
                    />
                    <PaginationList>{renderPagination()}</PaginationList>
                    <PaginationNext
                        disabled={page === maxPages}
                        onClick={() => {
                            console.log("page", page);
                            setPage(Math.min(maxPages, page + 1));
                        }}
                    />
                </Pagination>
            )}
        </>
    );
};

export default DeviceArray;
