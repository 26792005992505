import { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text, TextLink } from "../../components/text";
import { useJUHUU } from "../../context/JuhuuContext";

interface ProductListRouteProps {}

const ProductListRoute: React.FC<ProductListRouteProps> = () => {
  const [productArray, setProductArray] = useState<JUHUU.Product.Object[]>([]);
  const juhuu = useJUHUU();

  const { property } = useProperty();
  const handleRefresh = useCallback(async () => {
    if (property?.id === undefined) {
      return;
    }

    const query = await juhuu.products.list({
      propertyId: property?.id,
    });

    if (query.ok === false) {
      return;
    }

    setProductArray(query.data);
  }, [property, juhuu]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <Heading>Products</Heading>
      <Text className="mb-8">
        To market the products your integrated into the JUHUU platform, you can
        list them on{" "}
        <TextLink
          href="https://marketplace.juhuu.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          marketplace.juhuu.app
        </TextLink>
        .
      </Text>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {productArray.map((product) => {
            return (
              <TableRow key={product.id}>
                <TableCell>{product.name} </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default ProductListRoute;
